import type { ItemModel, DataItemModel } from 'o365.modules.DataObject.Types.ts';

// Helpoer functions used by DataItems to implement JSON fields

/** Convert JSON item into a proxy object for a DataItemModel */
export function getJsonItem<T extends ItemModel = ItemModel>(pJsonItem: string | Record<string, any>, pJsonAlias: string, pSourceField: keyof T & string, pSourceItem: DataItemModel<T>) {
    if (typeof pJsonItem === 'string') {
        pJsonItem = parseJson(pJsonItem);
    }
    if (pJsonItem == null) {
        pJsonItem = {};
    }
    return new Proxy(pJsonItem, getJsonProxyHandler(pJsonAlias, pSourceField, pSourceItem));
}

/** Create a proxy hanlder for a JSON field */
function getJsonProxyHandler<T extends ItemModel = ItemModel>(pJsonAlias: string, pSourceField: keyof T & string, pSourceItem: DataItemModel<T>) {
    return {
        get(target: Record<string, any>, prop: any): any {
            if (target[prop] != null && (target[prop].constructor.name === 'Object' || target[prop].constructor.name === 'Array')) {
                return new Proxy(target[prop], getJsonProxyHandler(pJsonAlias, pSourceField, pSourceItem));
            }
            return target[prop];
        },
        set(target: Record<string, any>, prop: any, value: any) {
            if (value === null || value === '') {
                delete target[prop];
            } else {
                target[prop] = value;
            }

            (pSourceItem[pSourceField] as any) = JSON.stringify(cleanEmptyValues(pSourceItem[pJsonAlias]));
            return true;
        }
    };
}


/** Helper class for cleaing up empty JSON object values */
function cleanEmptyValues(pObject: Record<string, any>) {
    Object.entries(pObject).forEach(([key, value]) => {
        if (value && typeof value === 'object') {
            cleanEmptyValues(value);
        }
        if ((value && typeof value === 'object' && Object.keys(value).length === 0) || value == null) {
            if (Array.isArray(pObject)) {
                pObject.splice(parseInt(key), 1);
            } else {
                delete pObject[key];
            }
        }
    });
    return pObject;
}


/** Helper function for safe JSON parsing */
function parseJson(pJson: string): Record<string, any> {
    try {
        return JSON.parse(pJson);
    } catch {
        return {}
    }
};